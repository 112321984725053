<template>
  <div class="grid">
    <div class="col-12">

      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Ferramentas
        <span class="text-primary text-5xl">/</span> Profissionais
        <span class="text-primary text-5xl">/</span> Gerenciar
      </div>
      <div class="text-500 mb-5">
        Listagem de profissionais
      </div>
      <div class="card">
        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <InputText v-model="filter" style="width: 80%" placeholder="Pesquisar" @change="filtrarDocumentos" />
            </div>
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Logs" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openBasicLogs" />
            <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openBasic" />
          </div>
          <Dialog :header="this.formInfo ? 'Editar' : 'Cadastro'" v-model:visible="displayBasic"
            :style="{ width: '55vw' }">
            <TabView :scrollable="true" v-model:activeIndex="active">
              <!-- profissional / adicionar profssional -->
              <TabPanel header="Cadastro" @click="active = 0">
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
                  <div style="display: flex; flex-direction: column;">
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; align-items: center; justify-content: space-between;">
                      <label>Nome</label>
                      <InputText id="name" required="true" v-model.trim="form.name" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.name }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label for="name">CNH</label>
                      <InputText id="cnh" v-model.trim="form.cnh" required="true" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.cnh }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label for="name">Vencimento da CNH</label>
                      <InputMask id="CNHDueDate" mask="99/99/9999" placeholder="00/00/0000"
                        v-model.trim="form.CNHDueDate" required="true" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.CNHDueDate }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label>Tipo</label>
                      <Dropdown style="max-width: 180px; min-width: 180px;" v-model="form.typeCnh" :options="typeCnh"
                        optionLabel="label" placeholder="Escolha" :class="{ 'p-invalid': submitted && !form.typeCnh }" />
                    </div>
                    <div class="field-checkbox"
                      style="display: flex; align-items: center; margin-top: 20px; justify-content: space-between;">
                      <div style="display: flex; align-items: center; justify-content: space-between;">
                        <Checkbox v-model="form.isAdvanced" :binary="true"
                          :class="{ 'p-invalid': submitted && !form.isAdvanced }" />
                        <div style="margin-left: 10px;">
                          <label for="binary">Possui adiantamento?</label>
                        </div>
                      </div>
                    </div>
                    <div
                      style="display: flex; align-items: center; margin-top: 10px; max-width: 320px; min-width: 320px;">
                      <Checkbox v-model="form.isNotification" :binary="true" />
                      <div style="margin-left: 10px;">
                        <label for="binary">Notificar profissional?</label>
                      </div>
                    </div>
                    <div
                      style="display: flex; align-items: center; margin-top: 10px; max-width: 320px; min-width: 320px;">
                      <label style="max-width: 100px; min-width: 100px;">{{ form.isNotification ? 'E-mail' : '' }}</label>
                      <InputText v-model="form.notificationUserEmail"
                        :style="{ display: form.isNotification ? 'flex' : 'none' }" />
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 10px;;">
                      <label style="max-width: 100; min-width: 100px;">{{ form.isNotification ? 'Whasapp' : '' }}</label>
                      <InputText v-model="form.notificationUserWhatsapp"
                        :style="{ display: form.isNotification ? 'flex' : 'none' }" />
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column;">
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; align-items: center; justify-content: space-between;">
                      <label>Serviços habilitados</label>
                      <Dropdown v-model="form.enabledServices" style="max-width: 200px; min-width: 200px;"
                        :options="enabledServices" optionLabel="label" placeholder="Escolha"
                        :class="{ 'p-invalid': submitted && !form.enabledServices }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label for="name">Data vencimento certificado</label>
                      <InputMask id="dueDate" mask="99/99/9999" placeholder="00/00/0000" v-model.trim="form.dueDate"
                        required="true" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.dueDate }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label>Número do contrato</label>
                      <Dropdown style="max-width: 180px; min-width: 180px;" v-model="form.contract_model"
                        :options="contract_model" optionLabel="label" placeholder="Escolha"
                        :class="{ 'p-invalid': submitted && !form.contract_model }" />
                    </div>
                    <div
                      style="display: flex; max-width: 320px; min-width: 320px; margin-top: 5px; align-items: center; justify-content: space-between;">
                      <label>Competências</label>
                      <MultiSelect style="max-width: 180px; min-width: 180px;" v-model="form.idsSkills"
                        :options="formSkills" optionLabel="label" placeholder="Escolha"
                        :class="{ 'p-invalid': submitted && !form.idsSkills }" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <!-- profissional vincular cliente -->
              <TabPanel :disabled="this.formInfo ? false : true" header="Vincular ao cliente" @click="active = 2">
                <div class="grid">
                  <div class="col-12">
                    <div class="card">
                      <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                        <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                          <div
                            style="display: flex; align-items: center; justify-content: space-between; max-width: 450px; min-width: 450px;">
                            <label>Nome</label>
                            <InputText style="margin-left: 10px; max-width: 220px; min-width: 220px;"
                              v-model.trim="addClients" />
                            <Button @click="addClient" label="Adicionar" icon="pi pi-plus"
                              class="p-button p-button-text mr-2" />
                          </div>
                        </div>
                      </div>
                      <Toast />
                      <div class="col-12 p-fluid painel painel-2 ">
                        <DataTable dataKey="id" :value="clients" :row-hover="true" responsiveLayout="scroll"
                          breakpoint="640px" :paginator="true" :rows="10"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          :rowsPerPageOptions="[10, 20, 50]"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          @row-click="openModalInfoClients($event)" style="cursor: pointer">
                          <template #empty> Nenhum registro encontrado. </template>

                          <template #loading> Carregando registros. Aguarde ... </template>
                          <Column field="clients" header="Nome do cliente">
                            <template #body="slotProps">
                              {{ slotProps?.data || '-' }}
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <!-- profissional / avaliaçao -->
              <TabPanel :disabled="true" header="Avaliação" @click="active = 3">
                <div class="grid">
                  <div class="col-12">
                    <div class="card">
                      <Toast />
                      <div class="col-12 p-fluid painel painel-2 ">
                        <DataTable v-if="!loading" dataKey="id" :value="avaliation" :row-hover="true" responsiveLayout="scroll"
                          breakpoint="640px" :paginator="true" :rows="10"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          :rowsPerPageOptions="[10, 20, 50]"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          @row-click="openModalInfoDocuments($event)" style="cursor: pointer">
                          <template #empty> Nenhum registro encontrado. </template>
                          <template #loading> Carregando registros. Aguarde ... </template>
                          <Column field="customer" header="Nome do cliente">
                            <template #body="slotProps">
                              <label>
                                {{ slotProps.data?.nameClient || '-' }}
                              </label>
                            </template>
                          </Column>
                          <Column field="data" header="Data " />
                          <Column field="assessment" header="Avaliação">
                            <template #body="slotProps">
                              <label>
                                {{ slotProps.data?.evaluation || '-' }}
                              </label>
                            </template>
                          </Column>
                          <Column field="observation" header="Observação" />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                  <small class="p-error" v-if="submitted && (
                    !form.name ||
                    !form.CNHDueDate ||
                    !form.cnh ||
                    !form.cntypeCnhh ||
                    !form.isAdvanced ||
                    !form.enabledServices ||
                    !form.dueDate ||
                    !form.contract_model
                  )">
                    Preencha todos os campos.
                  </small>
                </div>
              </TabPanel>
            </TabView>
            <!-- botao salvar / voltar -->
            <div class="flex mt-5">
              <Button label="Salvar" :loading="loadingSave" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
              <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="closeBasic" />
            </div>
          </Dialog>
          <Dialog header="Logs" v-model:visible="displayLogs" :style="{ width: '55vw' }">
            <div class="grid">
              <div class="col-12">
                <div class="card">
                  <Toast />
                  <Dropdown
                    :loading="loadingLogs"
                    style="max-width: 180px; min-width: 180px;"
                    v-model="selectedProfessional"
                    :options="splitAllProfessionals"
                    @change="getLogs"
                    optionLabel="label"
                    :placeholder="this.splitAllProfessionals?.length > 0 ? 'Selecione um Profissional' : 'Nenhum registro encontrado'"
                    :filter="true"
                    :showClear="true"
                    @filter="filtrarDocumentos"
                  />
                  <div class="col-12 p-fluid painel painel-2 ">
                    <DataTable
                      v-if="!loading"
                      dataKey="id"
                      :value="formLogs"
                      :row-hover="true"
                      responsiveLayout="scroll"
                      breakpoint="640px"
                      :rows="10"
                      @row-click="openModalInfoDocuments($event)"
                      style="cursor: pointer"
                    >
                      <template #empty> Nenhum registro encontrado. </template>
                      <template #loading> Carregando registros. Aguarde ... </template>
                      <Column field="logs" header="Id">
                        <template #body="slotProps">
                          {{ slotProps.data?.id || '-' }}
                        </template>
                      </Column>
                      <Column field="logs" header="Status">
                        <template #body="slotProps">
                          {{ slotProps.data?.status || '-' }}
                        </template>
                      </Column>
                      <Column field="logs" header="Última atualização">
                        <template #body="slotProps">
                          {{ slotProps.data?.updatedAt ? formatDate(slotProps.data.updatedAt) : '-' }}
                        </template>
                      </Column>
                    </DataTable>
                    <Paginator
                      :rows="perPage"
                      :totalRecords="total"
                      :v-model:first="page"
                      @page="onPage($event)"
                      template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                      currentPageReportTemplate="Página {currentPage} de {totalPages}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <Toast />
        <!-- listagem de profissionais -->
        <AppLoadingWrapper v-if="loading" />
        <div class="col-12 p-fluid painel painel-2 ">
          <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            :row-hover="true"
            responsiveLayout="scroll"
            breakpoint="640px"
            :rows="10"
            @row-click="openModalInfoDocuments($event)"
            style="cursor: pointer"
          >
            <template #empty> Nenhum registro encontrado. </template>
            <template #loading> Carregando registros. Aguarde ... </template>
            <Column field="name" header="Profissional">
              <template #body="slotProps">
                {{ slotProps.data?.name || '-' }}
              </template>
            </Column>
            <Column field="outsourced" header="Última atualização">
              <template #body="slotProps">
                <label>
                  {{ slotProps.data?.updatedAt ? formatDate(slotProps.data.updatedAt) : '-' }}
                </label>
              </template>
            </Column>
            <Column field="expirationCNH" header="Vencimento">
              <template #body="slotProps">
                <label>
                  {{ slotProps.data?.dueDate ? formatDate(slotProps.data.dueDate) : '-' }}
                </label>
              </template>
            </Column>
            <Column field="outsourced" header="Avaliação">
              <template #body="slotProps">
                <label>
                  {{ slotProps.data?.evaluation || '4.0' }}
                </label>
              </template>
            </Column>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary"
                  @click="toggleMenu($event, slotProps.data)" />
              </template>
            </Column>
          </DataTable>
          <Paginator
            v-if="!loading"
            :rows="perPageLogs"
            :totalRecords="totalLogs"
            :v-model:first="pageLogs"
            @page="onPage($event)"
            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
          />
          <Menu ref="menu" :model="actionItems" :popup="true" />
          <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="records">Tem certeza que deseja excluir?</span>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="deletarProfissional" />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityService from "../../services/SecurityService";
import { lightFormat, subHours } from 'date-fns';
import { formatDropDawn } from '../../utils/Mask';

export default {
  data() {
    return {
      form: {},
      actionItems: [
        {
          label: 'Alterar',
          icon: 'pi pi-pencil',
          command: () => this.editProfissionals(this.actionRecord)
        },
        {
          label: 'Excluir',
          icon: 'pi pi-trash',
          command: () => this.confirmDeleteRecord(this.actionRecord)
        }
      ],
      formInfo: undefined,
      isAdvanced: false,
      isNotification: false,
      loading: false,
      submitted: false,
      page: 1,
      pageLogs: 1,
      total: 0,
      totalLogs: 0,
      totalPage: 0,
      totalPageLogs: 0,
      perPage: 10,
      perPageLogs: 10,
      displayBasic: false,
      displayLogs: false,
      displayEditRoute: false,
      deleteRecordDialog: false,
      active: 0,
      filters: null,
      idsSkills: [],
      filter: '',
      loadingSave: false,
      loadingLogs: false,

      enabledServices: [
        { label: "PGR", value: "pgr" },
        { label: "AET", value: "aet" },
        { label: "Ambos", value: "ambos" },
      ],
      typeCnh: [
        { label: "Próprio", value: "own" },
        { label: "Terceiro", value: "third" },
      ],
      contract_model: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
      ],

      timeout: null,
      records: [],
      allProfessionalsDefault: [],
      splitAllProfessionals: [],
      formLogs: [],
      avaliation: [],
      clients: [],
      addClients: '',
      allSkills: null,
      selectedProfessional: null,
    };
  },

  mounted() {
    this.$service = new SecurityService('/ferramentas/profissionais');
    this.$serviceAll = new SecurityService('/ferramentas/profissionais/todos');
    this.$serviceLogs = new SecurityService('/ferramentas/profissionais/historico');
    this.$serviceEvaluation = new SecurityService('/tools/professional/avaliations');
    this.$serviceSkills = new SecurityService('/ferramentas/profissionais/competencias');
    this.load();
  },

  watch: {
    page() {
      this.load();
    },
  },

  methods: {
    toggleMenu(event, data) {
      this.actionRecord = data
      this.$refs.menu.toggle(event);
    },
    confirmDeleteRecord() {
      this.deleteRecordDialog = true;
    },
    filtrarDocumentos(event) {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
          const verifyFilter = event.value || this.filter;
          const formatFilter = Array.isArray(verifyFilter) ? verifyFilter : [verifyFilter];
          const params = { filter: formatFilter };
          if (event.value) {
            const { data } = await this.$serviceAll.findAll(null, params);
            const getAllProfessionals = formatDropDawn(data?.items || []);
            this.splitAllProfessionals = getAllProfessionals;
          } else {
            const { data } = await this.$service.findAll(null, params);
            this.records = data?.items || [];
            this.total = data?.meta?.totalItems || 0;
            this.totalPage = data?.meta?.totalPages || 0;
          }
      }, 500);
    },
    async onPage(event) {
      const formatFilter = Array.isArray(this.filter) ? this.filter : [this.filter];
      const params = { page: event.page + 1, filter: formatFilter };
      if (this.displayLogs) {
        const { data } = await this.$serviceLogs.findAll(null, params);
        this.pageLogs = event.page + 1;
        this.formLogs = data?.items || [];
        this.totalLogs = data?.meta?.totalItems || 0;
        this.totalPageLogs = data?.meta?.totalPages || 0;
      } else {
        const { data } = await this.$service.findAll(null, params);
        this.page = event.page + 1;
        this.records = data?.items || [];
        this.total = data?.meta?.totalItems || 0;
        this.totalPage = data?.meta?.totalPages || 0;
      }
    },
    addClient() {
      if (this.addClients) {
        this.clients.push(this.addClients);
        this.addClients = '';
      }
    },
    openBasic() {
      this.formInfo = undefined;
      this.active = 0;
      this.form = {};
      this.submitted = false;
      this.displayBasic = true;
      this.clients = [];
    },
    closeBasic() {
      this.displayBasic = false;
    },
    openBasicLogs() {
      this.displayLogs = true;
      this.selectedProfessional = null;
      this.formLogs = [];
    },
    formatDate(dateString) {
      const formatDates = lightFormat(subHours(new Date(dateString), 3), 'dd/MM/yyyy');
      const formatHours = lightFormat(subHours(new Date(dateString), 3), 'HH:mm');
      return `${formatDates} às ${formatHours}h`;
    },
    async deletarProfissional() {
      try {
        await this.$service.remove(this.actionRecord?.id);
        this.deleteRecordDialog = false;
        this.$toast.add({ severity: 'success', summary: 'Profissional deletado com sucesso!', life: 3000 });
        this.load();
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Problemas ao deletar o Profissional!', life: 3000 });
      }
    },
    async save() {
      if (this.formInfo) {
        this.saveEdit()
        return
      }
      try {
        this.loadingSave = true;
        const formatSkills = this.form.idsSkills?.map((v) => v.value)
        this.submitted = true;
        const formatInverseCNH = this.form.CNHDueDate?.split('/');
        const formatInverseDueDate = this.form.dueDate?.split('/');
        const body = {
          name: this.form.name,
          cnh: this.form.cnh,
          cnhDueDate: `${formatInverseCNH[2]}-${formatInverseCNH[1]}-${formatInverseCNH[0]}`,
          type: this.form.typeCnh.value,
          enabledServices: this.form.enabledServices.value,
          isAdvanced: this.form.isAdvanced || false,
          isNotification: this.form.isNotification || false,
          email: this.form.notificationUserEmail,
          whatsapp: this.form.notificationUserWhatsapp,
          dueDate: `${formatInverseDueDate[2]}-${formatInverseDueDate[1]}-${formatInverseDueDate[0]}`,
          contractModel: this.form.contract_model.value,
          idsSkills: formatSkills,
          clients: this.clients,
        };
        const stringBody = JSON.stringify(body);
        await this.$service.save(stringBody);
        this.$toast.add({ severity: 'success', summary: 'Profissional salvo com sucesso!', life: 3000 });
        this.submitted = false;
        this.closeBasic();
        this.form = {};
        this.load();
      } catch (error) {
        this.loadingSave = false; 
        this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar a profissional!', life: 3000 });
      }
    },
    async editProfissionals(itemSelect) {
      const formatCnhDueDate = itemSelect?.cnhDueDate?.split('-');
      const formatDueDate = itemSelect?.dueDate?.split('-');
      const getDataType = this.typeCnh?.find((v) => v.value === itemSelect?.type);
      const getDataEnabledServices = this.enabledServices?.find((v) => v.value === itemSelect?.enabledServices);
      const getDataContractModel = this.contract_model?.find((v) => v.value === itemSelect?.contractModel);
      const getDataSkills = this.formSkills?.filter((v) => itemSelect?.idsSkills?.includes(v?.value));
      this.form = {
        name: itemSelect?.name,
        cnh: itemSelect?.cnh,
        CNHDueDate: `${formatCnhDueDate[2]}/${formatCnhDueDate[1]}/${formatCnhDueDate[0]}`,
        typeCnh: getDataType,
        enabledServices: getDataEnabledServices,
        isAdvanced: itemSelect?.isAdvanced,
        isNotification: itemSelect?.isNotification,
        email: itemSelect?.email,
        whatsapp: itemSelect?.whatsapp,
        dueDate: `${formatDueDate[2]}/${formatDueDate[1]}/${formatDueDate[0]}`,
        contract_model: getDataContractModel,
        idsSkills: getDataSkills,
      }
      this.clients = itemSelect?.clients || [];
      this.formInfo = itemSelect;
      this.displayBasic = true;
    },
    async saveEdit() {
      try {
        this.loadingSave = true;
        const formatSkills = this.form.idsSkills?.map((v) => v.value)
        this.submitted = true;
        const formatInverseCNH = this.form.CNHDueDate?.split('/');
        const formatInverseDueDate = this.form.dueDate?.split('/');
        const body = {
          name: this.form.name,
          cnh: this.form.cnh,
          cnhDueDate: `${formatInverseCNH[2]}-${formatInverseCNH[1]}-${formatInverseCNH[0]}`,
          type: this.form.typeCnh.value,
          enabledServices: this.form.enabledServices.value,
          isAdvanced: this.form.isAdvanced,
          isNotification: this.form.isNotification,
          email: this.form.notificationUserEmail,
          whatsapp: this.form.notificationUserWhatsapp,
          dueDate: `${formatInverseDueDate[2]}-${formatInverseDueDate[1]}-${formatInverseDueDate[0]}`,
          contractModel: this.form.contract_model.value,
          idsSkills: formatSkills,
          clients: this.clients,
        };
        const stringBody = JSON.stringify(body);
        await this.$service.save(stringBody, this.formInfo?.id);
        this.$toast.add({ severity: 'success', summary: 'Profissional salvo com sucesso!', life: 3000 });
        this.submitted = false;
        this.form = {};
        this.closeBasic();
        this.load();
      } catch (error) {
        this.loadingSave = false; 
        this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar a profissional!', life: 3000 });
      }
    },
    async getLogs(event) {
      this.loadingLogs = true;
      if (event?.value?.value) {
        try {
          const { data } = await this.$serviceLogs.findById(event.value.value);
          this.formLogs = data?.items || [];
          this.totalLogs = data?.meta?.totalItems || 0;
          this.totalPageLogs = data?.meta?.totalPages || 0;
        } catch (error) {
          this.loadingLogs = false;
          this.$toast.add({ severity: 'error', summary: 'Problemas ao listar logs', life: 3000 });
        }
      }
      this.loadingLogs = false;
    },
    async getAvaliation() {
      try {
        const { data } = await this.$serviceEvaluation.findAll();
        this.avaliation = data;
      } catch {
        this.$toast.add({ severity: 'error', summary: 'Problemas ao listar avaliações', life: 3000 });
      }
    },
    async getSkills() {
      try {
        const { data } = await this.$serviceSkills.findAll(null, {});
        const skillItem = formatDropDawn(data || []);
        this.formSkills = skillItem;
      } catch {
        this.$toast.add({ severity: 'error', summary: 'Problemas ao listar competências', life: 3000 });
      }
    },
    async getDataScreen() {
      // this.getAvaliation();
      this.getSkills();
    },
    async load() {
      try {
        this.loading = true;
        const { data } = await this.$service.findAll(null, {});
        this.records = data?.items || [];
        this.total = data?.meta?.totalItems || 0;
        this.totalPage = data?.meta?.totalPages || 0;
        const allData = await this.$serviceAll.findAll(null, {});
        const getAllProfessionals = formatDropDawn(allData?.data?.items || []);
        this.splitAllProfessionals = getAllProfessionals;
        this.getDataScreen();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.add({ severity: 'error', summary: 'Problemas ao listar Profissionais!', life: 3000 });
      }
    },
  },
}
</script>